@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@200;300&family=Petit+Formal+Script&family=Parisienne&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Dosis', sans-serif;
  font-size: 20px;
  color: #fff;
}

h1 {
  text-align: center;
  font-family: 'Parisienne', cursive;
  font-size: 76px;
  color: #ddd;
  padding-top: 40px;
  margin: 0 0 40px 0;
}

.y2022 h1 {
  color: rgb(112, 0, 0);
}

.y2021 h1 {
  font-family: 'Petit Formal Script', cursive;
  font-size: 56px;
  color: #fff;
}

h3 {
  margin: 0 0 20px;
  font-size: 36px;
}

main {
  padding: 0 40px 80px 40px;
  background: url(/static/media/bg.c3a19e22.jpg) no-repeat center top;
  background-size: cover;
  background-attachment: fixed;
}

.y2021 main {
  background: url(/static/media/bg-2021.48462b87.jpg) no-repeat center center;
}

.y2022 main {
  background: url(/static/media/bg-2022.ae5d4022.jpg) no-repeat center center;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin-left: 20px;
}

section {
  background: #22141499;
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin: 0 40px 40px 40px;
}


table td, th {
  padding: 5px;
  text-align: left;
  vertical-align: top;
  border-bottom: 1px dotted #ccc;
}

table tbody tr:first td {
  font-style: italic
}

td.dist {
  text-align: right;
  padding-right: 80px;
}

.form-wrapper form {
   display: inline-flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
}

.form-wrapper form > * {
  margin-right: 16px;
}

.watch-shortcut {
  text-align: center;
  margin: 1rem;
}

.previous-years {
  text-align: center;
  margin: -2em 0 2rem;
}

.previous-years a {
  color: rgb(249, 194, 66);
  font-size: 1.6rem;
  font-weight: bold;
}

.watch-shortcut a {
  color: rgb(249, 194, 66);
  font-size: 1.2rem;
}

input, button {
  font-family: 'Dosis', sans-serif;
  font-size: 28px;
  padding: 16px;
  font-weight: bold;
}

button {
  background: #2E5A1C;
  color: #fff;
  border: 0;
}

